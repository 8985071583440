import React from 'react'
import Layout from '../../components/layout'
import { GalleryCreator } from '../../components/ChallengeCreators'

const ChallengeCreatorPage = ({ learningGoalId }) => (
  <Layout>
    <GalleryCreator learningGoalId={learningGoalId} />
  </Layout>
)

export default ChallengeCreatorPage
